import React from 'react'
import { DisplayName } from '../Constants'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Navigator from './Navigator'

const NameHeader = styled.h1`
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 50px;
  color: #ffffff;
  margin: 4%;
  // border: 1px solid red;
`

const StyledHeader = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 1%;
  // border: 1px solid red;
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  // border: 1px solid red;
`

function Header() {
  return (
    <PageContainer>
      <StyledHeader>
        <NameHeader>{DisplayName}</NameHeader>
        <Navigator />
      </StyledHeader>
      <Outlet />
    </PageContainer>
  )
}

export default Header
