import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNav = styled.div`
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin: 1%;
    // border: 1px solid red;
`
const StyledLink = styled(Link)`
    text-decoration: none;
    font-family: monospace;
    font-size: 25px;
    font-weight: 400;line-height: 50px;
    cursor: pointer;
    color: #FFFFFF;
    // border: 1px solid red;
`

function Navigator () {
    return (
    <StyledNav>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/portfolio">Portfolio</StyledLink>
        <StyledLink to="/about">About</StyledLink>
    </StyledNav>
    );
}

export default Navigator;