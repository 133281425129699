import React from 'react';
import { DisplayName } from './Constants';
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";

const StyledApp = styled.div`
  text-align: center;
  font-family: monospace;
  background-color: #272727;
  min-height: 100vh;
`

function App() {
  return (
    <StyledApp>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </StyledApp>
  );
}

export default App;
